import React, { useState, useEffect } from "react";
import { Link, NavigationType, useNavigationType } from "react-router-dom";
import axios from "axios";
import ENDPOINT from "../../constants/api-endpoints";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../elements/LoadingSpinner";

const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};

const setScroll = () => {
  const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  localStorage.setItem("scrollPositionPuppet", winScroll);
};

const PuppetgramCast = () => {
  const isPop = useBackButton();
  const [isLoading, setIsLoading] = useState(true);
  const [puppetImages, setPuppetImages] = useState([]);
  useEffect(() => {   
    window.addEventListener("scroll", setScroll);
    return () => window.removeEventListener("scroll", setScroll); 
  })
  useEffect(() => {
    axios
      .get(ENDPOINT.PUPPETGRAM_CAST, {})
      .then((response) => {
        setPuppetImages(response.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, (2000));
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });    
      if (isPop) {
        let scrollPosition = localStorage.getItem("scrollPositionPuppet");
        console.log(scrollPosition);
        setTimeout(() => {
          window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
        }, (4000));
      }     
  }, [isPop]);


  // const addFavorite = (id) => {
  //   const postData = {
  //     'id' : id,
  //     'type' : 'puppet',
  //   };
  //   const response = axios.post(ENDPOINT.ADD_PUPPET_FAVORITE, postData, {})
  //   .then((response) => {
  //     toast(response.data.message);
     
  //   })
  //   .catch((error) => {
  //     if(error.response.status === 401){
  //       toast("Login first");
  //     }
  //   });
  // };

  const renderData = (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
        <ToastContainer />
        <div className="container-fluid">          
          <div className="row magin-two-side">
            <h1 className="puppetcast-title">
              <strong>Click on the Pictures to View Our Puppet Cast Profiles</strong>
            </h1>
            {puppetImages.map((images) => (
              <div className="col-sm-4">
                <div className="pfl_pc_bx">
                  <Link to={`/puppet-profile/${images.slug}`}>   
                    <img src={images.background_image_url} alt="thumbnail" />
                  </Link>
                  <p className="title_center">{images.name}</p>
                  {
                    // localStorage.getItem("BearerToken") !== null ? (<button onClick={() => addFavorite(images.id)} > <label>Add Favorite</label> </button> ) : ("")
                  }
                </div>
              
              </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
  return (
    <>
        {isLoading ? <LoadingSpinner /> : renderData}
    </>
  );
};

export default PuppetgramCast;
