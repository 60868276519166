import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useNavigate } from "react-router-dom";
import ENDPOINT from "../../constants/api-endpoints";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:  ENDPOINT.BASE_PATH + "/api/payment/stripe_ipn",
      },
    });
    if (result.error) {
      navigate('/payment-fail', { state: { message: "An Error occured with your payment " } });
      console.log(result.error.message);
    } else {
      console.log(result);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className='spl_btn mt-4' disabled={!stripe}>Pay Now!</button>
    </form>
  )
};

export default CheckoutForm;