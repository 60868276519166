import React, { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import Header from './elements/Header';
import Footer from './elements/Footer';
import CookieConsent from "react-cookie-consent";


const Layout = () => {
  const [isVisible, setIsVisible] = useState(true);
  
  useEffect(() => {   
    window.addEventListener("scroll", listenToScroll);
    return () => 
       window.removeEventListener("scroll", listenToScroll); 
  })

  const listenToScroll = () => {
    let heightToHideFrom = document.getElementById('header_main').offsetHeight;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {  
         isVisible && setIsVisible(false);
    } else {
         setIsVisible(true);
    }  
  };
  const clickToScroll = () => {
    var element = document.getElementById("scrollContentTarget");
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  return (
    <div>
        <Header />
        <Outlet />
        <Footer />
        <CookieConsent
          location="bottom"
          cookieName="puppetGramCookieConsent"
          style={{ background: "#2B373B" }}
          buttonText="I accept"
          expires={365}
          overlay
          enableDeclineButton
          buttonStyle={{ color: "#4e503b", fontSize: "13px", backgroundColor: "#F6AD14" }}
          declineButtonStyle={{ color: "#d6d6d6", fontSize: "13px", backgroundColor: "#CC4516" }}
          flipButtons
          setDeclineCookie={false}
          onDecline={() => {
            window.location.href="https://google.com";
          }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
         
        {
        isVisible 
         &&        
          <Link onClick={clickToScroll} className="float bounce" title="Click to see the page content">
            <i className="fa fa-angle-down my-float"></i>
          </Link>
        }   
    </div>
  );
};

export default Layout;