import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ENDPOINT from '../../constants/api-endpoints';
import parse from 'html-react-parser';
import LoadingSpinner from "../elements/LoadingSpinner";

const Pricing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [aboutus, setAboutUs] = useState([]);

  useEffect(() => {
    axios.get(ENDPOINT.STATIC_PAGE_DATA + '/pricing', {})
    .then((response) => {
      setIsLoading(false);  
       // Handle data
      setAboutUs(response.data.data);       
    })
    .catch((error) => {
      setIsLoading(false);  
      console.log(error);
    })
  }, []);

  let top_heading = '';
  let pageContent = '';
  let footer_heading = '';
  if (aboutus.length !== 0) {
      top_heading = aboutus.page_header_line;
      pageContent = aboutus.content;
      footer_heading = aboutus.page_footer_line;
  }

  const renderData = (
    <>
      <section className="full_width common_heading static-page" id="scrollContentTarget">
        <div className="container-fluid">
          <div className="row magin-two-side priceTable">
            <h1><strong> { top_heading }</strong></h1>
            { parse(pageContent) }
            <h1><strong>{ footer_heading }</strong></h1>
          </div>
        </div>
      </section>
    </>
  );
  return (
    <>
        {isLoading ? <LoadingSpinner /> : renderData}
    </>
  );
};

export default Pricing;
