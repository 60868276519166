import React from "react";
import logo_name from "../../css/images/logo_name.png";

const FormLogo = ({title}) => {

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <div className="of_logo">
          <img src={logo_name} alt="logo" /> 
        </div>
        <h2>{ title && title }</h2>
      </div>
    </>
  );

};

export default FormLogo;
