import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";

import sample_stationary from "../../css/video/stationary_sample.mp4";
import sample_rotating from "../../css/video/rotating_sample.mp4";
import sample_video from "../../css/video/video_sample.mp4";
import EasyStepsVideo from "../../css/video/5EasyStepsSmall.mp4";
import abt_blk_stp1 from "../../css/images/abt_blk_stp1.png";
import abt_stp1 from "../../css/images/abt_stp1.png";
import abt_blk_stp2 from "../../css/images/abt_blk_stp2.png";
import abt_stp2 from "../../css/images/abt_stp2.png";
import abt_blk_stp3 from "../../css/images/abt_blk_stp3.png";
import abt_stp3 from "../../css/images/abt_stp3.png";
import abt_blk_stp4 from "../../css/images/abt_blk_stp4.png";
import abt_stp4 from "../../css/images/abt_stp4.png";
import abt_blk_stp5 from "../../css/images/abt_blk_stp5.png";
import abt_stp5 from "../../css/images/abt_stp5.png";
import esy_stps from "../../css/images/esy_stps.png";
import abt_blk_1 from "../../css/images/abt_blk_1.png";
import v_b_hdg from "../../css/images/v_b_hdg.png";
import abt_vdo_ctg_1 from "../../css/images/abt_vdo_ctg_1.png";
import abt_vdo_ctg_2 from "../../css/images/abt_vdo_ctg_2.png";
import abt_vdo_ctg_3 from "../../css/images/abt_vdo_ctg_3.png";
import let_get_strtd from "../../css/images/let_get_strtd.png";


const GettingStarted = () => {
  const [show, setShow] = useState(false);
  const [videoOption, setVideo] = useState("");
  const [videoTitle, setVideoTitle] = useState("");

  const handleClose = () => {
    setShow(false);
  }
  const videoShow = (opt,title) => {
    setVideo(opt);
    setVideoTitle(title);
    setShow(true);
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>{videoTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video width="100%" controls autoplay="true" >
            <source src={videoOption} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <section className="full_width common_heading" id="scrollContentTarget">
        <div className="container gs-cont">
            <div className="abt_cnt_1">
                <h4 className="text-end gs-title-width"><img src={esy_stps} /></h4>
                <div className="gs-5s-video text-center">
                  <img src={abt_blk_1} onClick={() => videoShow(EasyStepsVideo,"5 Easy Steps")} title="Click to see the video" alt="Click to see the video" />
                  <div className="gs-5s-pb text-center">
                    <a href="#" onClick={() => videoShow(EasyStepsVideo,"5 Easy Steps")} title="Click to see the video" alt="Click to see the video">Play</a>
                  </div>
                </div>                
            </div>
            <div className="container abt_stps">
                <div className="row">
                    <div className="col-sm-6">
                        <img src={abt_blk_stp1} />
                    </div>
                    <div className="col-sm-6 stp__ttl non-talign-right">
                        <img src={abt_stp1} />
                        <h3>pick your puppet</h3>
                        <p>Each puppet has its own unique profile page so you can get to know them better. Check out all the profiles to find the puppet that's right for you.</p>
                    </div>
                </div>
                <div className="row flex-sm-row-reverse">
                <div className="col-sm-6">
                        <img src={abt_blk_stp2} />
                    </div>
                    <div className="col-sm-6 stp__ttl talign-right">
                        <img src={abt_stp2} />
                        <h3>pick your background</h3>
                        <p className='righTextPara'>We offer a variety of categories & within each category you can choose whether you want a stationary image, a rotating background a video or a video loop  We have lots of otions for you to choose from.</p>
                    </div>
                </div>
                <div className="row">
                <div className="col-sm-6">
                        <img src={abt_blk_stp3} />
                    </div>
                    <div className="col-sm-6 stp__ttl non-talign-right">
                        <img src={abt_stp3} />
                        <h3>your message</h3>
                        <p>Tell us what you want your puppet to say in your custom video message. Also this is where you can add any special requests which will be submitted for review.</p>
                    </div>
                </div>
                <div className="row flex-sm-row-reverse">
                <div className="col-sm-6">
                        <img src={abt_blk_stp4} />
                    </div>
                    <div className="col-sm-6 stp__ttl talign-right">
                        <img src={abt_stp4} />
                        <h3>choose video length</h3>
                        <p>We offer a few choices for your video length. These options include <br/>30 seconds- 1 minute - 2 minutes - OTHER</p>
                            
                        <p>If you are interested in a longer video length than our 3 basic packages, select other and we will contact you to price your custom video.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <img src={abt_blk_stp5} />
                    </div>
                    <div className="col-sm-6 stp__ttl non-talign-right">
                        <img src={abt_stp5} />
                        <h3>video view option</h3>
                        <p>We offer the options of online viewing your custom puppet video through our YouTube channel or we can send you a direct link to your downloadable movie file. </p>
                    </div>
                </div>
            </div>     
        </div>
      </section>
      <section className="full_width vdo_bg_optn">
          <div className="container gs-cont">
              <h3><img src={v_b_hdg} /></h3>
              <p>Click on each of the 3 options to view a video sample of what our <br/>Stationary, Rotating & Video/Loop background styles look like.</p>
              <h4>Then pick the one that's right for you.</h4>
                  <ul className="d-flex">
                      <li><img src={abt_vdo_ctg_1} onClick={() => videoShow(sample_stationary,"Stationary")} title="Click to see the video" alt="Click to see the video" /></li>
                      <li><img src={abt_vdo_ctg_2} onClick={() => videoShow(sample_rotating,"Rotating")} title="Click to see the video" alt="Click to see the video" /></li>
                      <li><img src={abt_vdo_ctg_3} onClick={() => videoShow(sample_video,"Video/Loop")} title="Click to see the video" alt="Click to see the video" /></li>
                  </ul>
          </div>
      </section>
      <section className="full_width">
          <div className="container text-center">
              <h5 className="gt_st_btn_hdng">CLICK BELOW TO GET YOUR PERSONAL VIDEO STARTED</h5>
              <Link to="/puppet-profiles"><img src={let_get_strtd} /></Link>
          </div>
      </section>
    </>
  );
};

export default GettingStarted;
