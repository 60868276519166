import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import ENDPOINT from "../../constants/api-endpoints";
import parse from "html-react-parser";
import abt_us_title from "../../css/images/abt_us_title.png";
import abt__us__sm_img from "../../css/images/abt__us__sm-img.png";
import img_abt_hdng from "../../css/images/abt_hdng.png";
import img_abt_pr_ctgy_1 from "../../css/images/abt_pr_ctgy_1.png";
import img_abt_pr_ctgy_2 from "../../css/images/abt_pr_ctgy_2.png";
import img_abt_pr_ctgy_3 from "../../css/images/abt_pr_ctgy_3.png";
import img_testimonial_head from "../../css/images/testimonial_head.png";
import sample_stationary from "../../css/video/stationary_sample.mp4";
import sample_rotating from "../../css/video/rotating_sample.mp4";
import sample_video from "../../css/video/video_sample.mp4";
import LoadingSpinner from "../elements/LoadingSpinner";

const AboutUs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [videoOption, setVideo] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [aboutus, setAboutUs] = useState([]);

  const handleClose = () => {
    setShow(false);
  }
  const videoShow = (opt,title) => {
    setVideo(opt);
    setVideoTitle(title);
    setShow(true);
  }

  useEffect(() => {
    const postData = {
      'page_category_id' : 3,
    };
    axios.post(ENDPOINT.PAGEDATA, postData, {})
    .then((response) => {
      setIsLoading(false);      
      setAboutUs(response.data.data);
    })
    .catch((error) => {
      setIsLoading(false);
      console.log(error);
    })
  }, []);

  let block1Content = aboutus[0]?.content;
  let block2Content = aboutus[1]?.content;
  let block2Footer = aboutus[1]?.page_footer_line;
  let block3Content = aboutus[2]?.content;
  let block3Footer = aboutus[2]?.page_footer_line;
  const renderData = (
    <>
      <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>{videoTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video width="100%" controls autoplay="true" >
            <source src={videoOption} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <section className="full_width common_heading" id="scrollContentTarget">
          <div className="abt__cnt abt-cont-pad">
            <div className="container">
                <img src={abt_us_title} alt="abount us img 1" />
                { parse(`${block1Content}`) }
                <div className='abt_p_style1'>{ parse(`${block2Content}`) }</div>
                { parse(`${block2Footer}`) }
                <div className="d-flex flex-wrap justify-content-between">
                    <div className="abt__md__blk">
                    { parse(`${block3Content}`) }                   
                    </div>
                    <div><img src={abt__us__sm_img} alt="abount us img 2" /></div>  
                </div>
                <div className='abt_p_style1'>{ parse(`${block3Footer}`) }</div>
            </div>
            <div className="abt__tsti__blk">
                <div className="container">
                    <div>
                        <img src={img_testimonial_head} alt="testimonial Heading" />
                    </div>
                    <div className="testimonial_bdy">
                        <p>I love Puppetgram and the video they made for me for my birthday. It <br/>made me so very happy to receive such a fun birthday card. I'm a really big fan of puppets so that made it even more special. </p>
                        <h5 className="text-end">Scott Hodgkinson</h5>
                    </div>
                    <div className="testimonial_bdy">
                        <p>We had a custom video made for our daughter for the Summer Solstice. It was a HIT!!! She loved it so much, we had to play it over and over and over and over and over (you get the idea) for her. Thank you PuppetGram for such a fun gift. </p>
                        <h5 className="text-end">Tiffany Mitchell</h5>
                    </div>
                </div>
            </div>
          </div>
          <div className="container">
              <div className="abt_cnt_1">
                  <div className="text-center">
                    <img src={img_abt_hdng} alt="About Us Heading"/>
                  </div>
              </div>
              <div className="abt_cnt_2 abt_bd_bg">
                  <div className="abt_bd_bg_tp">
                      <p>Click on each of the 3 options to view a video sample of what our Stationary, Rotating & Video/Loop background styles look like.</p>
                      <h4>Then pick the one that's right for you.</h4>
                  </div>
                  <div className="abt_frm_mdl text-center">
                      <div className="abt_frm_md1"><img src={img_abt_pr_ctgy_1} onClick={() => videoShow(sample_stationary,"Stationary")} title="Click to see the video" alt="Click to see the video" /></div>
                      <div className="abt_frm_md2"><img src={img_abt_pr_ctgy_2} onClick={() => videoShow(sample_rotating,"Rotating")} title="Click to see the video" alt="Click to see the video" /></div>
                  </div>
                  <div className="abt_bd_bg_btm">
                      <div className="abt_frm_md3"><img src={img_abt_pr_ctgy_3} onClick={() => videoShow(sample_video,"Video/Loop")} title="Click to see the video" alt="Click to see the video" /></div>
                  </div>
              </div>        
            </div>
      </section>
    </>
  );
  return (
    <>
        {isLoading ? <LoadingSpinner /> : renderData}
    </>
  );
};

export default AboutUs;
