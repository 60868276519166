import React, { useState, useEffect } from "react";
import FormLogo from "../elements/FormLogo";
import { Formik, Field, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import ENDPOINT from "../../constants/api-endpoints";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/message.css';
import userImg from "../../css/message/user.jpg";
import adminImg from "../../css/message/admin.jpg";
import formatDate from "../elements/DateFormat";
import { useNavigate } from "react-router-dom";

// Define Yup validation schema
const validationSchema = Yup.object().shape({
  message: Yup.string().required("Message is required"),
});

// Initial form values
const initialValues = {
  message: "",
};

const Message = () => {
  const navigate = useNavigate()
  const userLoginOrNot = localStorage.getItem("BearerToken");
  if (userLoginOrNot == null) {
    navigate("/login");
  }
  const [myMessage, setMyMessage] = useState([]);  
  useEffect(() => {
    axios
      .get(ENDPOINT.MY_MESSAGE_LIST, {})
      .then((response) => {
        setMyMessage(response.data.data);
       
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Assuming your API endpoint is "/api/submitForm"
    
      const response = await axios.post(ENDPOINT.MESSAGE_SEND, values);
      if (response.data.status) {
        toast(response.data.message);
        resetForm({});
        setTimeout(() => {
          // navigate("/message");
          window.location.reload();
        }, 3000);
      
      } else {
        toast(response.data.message);
      }
      // Handle the successful response
      // console.log("Server Response:", response.data);
    } catch (error) {
      // Handle errors
      console.error("Submission Error:", error);
      // Reset form after an error if needed
      setSubmitting(false);
    }
  };

  return (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
      <ToastContainer />
        <div className="container">
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
          <div className="odr_frm">
            <FormLogo title="Message" key={1} />

            <div className="of_bx_alfa" style={{ maxHeight: "500px", overflowY: "scroll" }}>
              {myMessage.map((data) => (
                <div className="row">
                  {data.type === 'sent' ? (
                    <div className="message-container darker">
                      <img src={ userImg } alt="Avatar" className="right" style={{ width: "100%" }} />
                      <p>{data.message}</p>
                      <span className="time-left">{formatDate(new Date(data.created_at), 5)}</span>
                    </div>
                   ) : (
                    <div className="message-container darker">
                      <img src={adminImg} alt="Avatar" style={{ width: "100%" }} />
                      <p>{data.message}</p>
                      <span className="time-right">{formatDate(new Date(data.created_at), 5)}</span>
                    </div>
                   )}
               </div>
              ))}
            </div>

            <Form>
            <div className="of_bx_alfa">
              <div className="row">
                <div className="col mb-4">
                  <div className="d-flex">
                    <Field as="textarea" rows={3} cols={30} id="message"  name="message" placeholder="Enter message here!" /> 
                    <ErrorMessage name="message" component="div" />
                  </div>
                </div>
                <div className="d-flex mb-4 align-items-center justify-content-center">
                  <input type="submit" defaultValue="Submit" />
                </div>
              </div>
            </div>
            </Form>
          </div>
          </Formik>
        </div>
      </section>
    </>
  );
};

export default Message;
