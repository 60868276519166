import React, { useState, useEffect } from 'react';
import FormLogo from '../elements/FormLogo';
import LoadingSpinner from "../elements/LoadingSpinner";

const PaymentFail = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, (2000));
  }, []);

  const renderData = (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
        <div className="container">
          <div className="odr_frm">
              <FormLogo title="" />  
              <div className="of_bx_alfa">
                <div className="row">
                  <div className="col-sm-12" style={{ textAlign: "center" }}>
                    <h2>Payment Failed!</h2>
                    <p>Something went wrong. Please retry payment or contact to admin!</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
  return (
    <>
        {isLoading ? <LoadingSpinner /> : renderData}
    </>
  );
};

export default PaymentFail;
