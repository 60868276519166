import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ENDPOINT from "../../constants/api-endpoints";
import { Link } from "react-router-dom";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../elements/LoadingSpinner";


const MyFavoriteBackground = () => {
  const navigate = useNavigate();
  const userLoginOrNot = localStorage.getItem("BearerToken");
  if (userLoginOrNot == null) {
    navigate("/login");
  }

  const [isLoading, setIsLoading] = useState(true);
  const [myFavBackground, setMyFavoriteBackground] = useState([]);

  useEffect(() => {
    axios
      .get(ENDPOINT.MY_FAVORITE_BACKGROUND, {})
      .then((response) => {
        setIsLoading(false);
        setMyFavoriteBackground(response.data.myFavoriteBackground);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  const selectBackground = (event) => {
    const id = event.currentTarget.getAttribute('value');
    navigate('/order-now',{state:{backgroundImageID:id, }});
  }

  const addFavorite = (id) => {
    const postData = {
      'id' : id,
      'type' : 'background_category',
    };
    const response = axios.post(ENDPOINT.ADD_PUPPET_FAVORITE, postData, {})
    .then((response) => {
      toast(response.data.message);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    })
    .catch((error) => {
      if(error.response.status === 401){
        toast("Login first");
      }
    });
  };

  const renderData = (
    <>
      <section className="full_width common_heading">
        <div className="container-fluid">
          <nav className="main-nav">
            <ul className="favorite">
              <li>
                <Link to="/my-favorite">Puppet</Link>
              </li>
              <li>
                <Link to="/my-favorite-background" className="active">Background</Link>
              </li>
            </ul>
          </nav>
        
          <div className="row">
            {myFavBackground.length > 0 ? myFavBackground?. map((images) => (
              <div className="col-sm-4">
                <div className="pfl_pc_bx">
                  {images?.type === 'video' ? (
                      <video width="100%" height="auto" controls>
                        <source src={images?.file_url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={images?.file_url} alt="thumbnail" />
                    )
                  }
                </div>
                <div className="selectFavBtn">
                  { localStorage.getItem("BearerToken") !== null ? (<button className="spl_btn rmv_fav" onClick={() => addFavorite(images.id)} > <label>Remove Favorite</label> </button> ) : ("") }
                  { localStorage.getItem("BearerToken") !== null ? (<button className="spl_btn"  onClick={selectBackground} value={images.id}> <label>Select Background</label> </button> ) : ("") }
                </div> 
              </div>
              )) : 
              (
                <div className="noRecords">No Records</div>
              )
              }
          </div>

        </div>
      </section>
    </>
  );
  return (
    <>
        {isLoading ? <LoadingSpinner /> : renderData}
    </>
  );
};

export default MyFavoriteBackground;
