const localSettings = {
	API_END_POINT_URL: 'https://webadmin.puppet-gram.com',
	APP_URL: 'https://localhost:3000'
};
  
const devSettings = {
  API_END_POINT_URL: 'https://webadmin.pg.mybetasite.net',
  APP_URL: 'https://pg.mybetasite.net'
};

const prodSettings = {
  API_END_POINT_URL: 'https://webadmin.puppet-gram.com',
  APP_URL: 'https://puppet-gram.com'
};

const prepareSettings = () => {
	let settings;
	switch (document.location.hostname) {
		case 'puppet-gram.com':
			settings = prodSettings;
			break;
		case 'pg.mybetasite.net':
			settings = devSettings;
			break;
		default:
			settings = localSettings;
	}
	return settings;
};
  
export default prepareSettings();
