import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Lightbox } from "react-modal-image";
import axios from "axios";
import ENDPOINT from "../../constants/api-endpoints";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../elements/LoadingSpinner";


const removeHyphensAndCapitalize = (str) => {
  // Split the string by hyphens and capitalize the first letter of each word
  return str.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};
const CategoryImgVideo = () => {  
  const [isLoading, setIsLoading] = useState(true);
  let { slug } = useParams();
  const [categoryImageVideo, setCategoryImagesVideo] = useState([]);
  const navigate = useNavigate()
  const [removeFaviorate, setRemoveFaviorate] = useState(0);
  const [loginUserID, setLoginUserID] = useState(localStorage.getItem("loginUserId") || 0);


  useEffect(() => {
    axios.get(ENDPOINT.CATEGORY_IMAGES_VIDEO + "/"+ slug + "/" + loginUserID, {})
      .then((response) => {        
        setCategoryImagesVideo(response.data.data);
        setRemoveFaviorate(response.data.data.favoritesFlag);
        setTimeout(() => {
          setIsLoading(false);
        }, (2000));
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      }); 
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, (2000));
  }, []);

  const categoryName = (categoryImageVideo.categoryTitle) ? categoryImageVideo.categoryTitle : removeHyphensAndCapitalize(slug);

  const selectBackground = (event) => {
    const id = event.currentTarget.getAttribute('value');
    navigate('/order-now',{state:{backgroundImageID:id, }});
  }

  const addFavorite = (id) => {
    const postData = {
      'id' : id,
      'type' : 'background_category',
    };
    const response = axios.post(ENDPOINT.ADD_PUPPET_FAVORITE, postData, {})
    .then((response) => {
      toast(response.data.message);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    })
    .catch((error) => {
      if(error.response.status === 401){
        toast("Login first");
      }
    });
  };
  const [showLightbox, setShow] = useState(false);
  const [lightboxUrl, setLightboxUrl] = useState("");
  const closeLightbox = () => {
    setShow(false);
  };
  const openLightbox = (url) => {
    setLightboxUrl(url)
    setShow(true);
  };
  const renderData = (
    <>
      {showLightbox && (<Lightbox
                      large={lightboxUrl}
                      onClose={closeLightbox}
                    />)}      
      <section className="full_width common_heading" id="scrollContentTarget">
        <ToastContainer />
        <div className="container-fluid cont-pad">                   
          <div className="row magin-two-side">
            <h1 className="subcategorypage-title">{categoryName}</h1>
            <h2 className="color-white subcategorypage-middle-text">
              Click on the picture to View the background image<br/>Choose background by checking box under selected image.
            </h2>
            {(categoryImageVideo.imageData && categoryImageVideo.imageData.length != 0) &&
              <>
                <h3 className="subcategorypage-type-title">Stationary Backgrounds</h3>
                {categoryImageVideo.imageData?. map((data) => (
                  <div className="col-sm-4">
                    <div className="pfl_pc_bx mb-2 img-overflow-fit">
                      <img className="c-pointer" src={data.file_url} alt="Stationary" onClick={() => openLightbox(data.file_url)} />
                    </div>
                    <div className="selectFavBtn">
                      {data.favoritesFlag === 0 && localStorage.getItem("BearerToken") !== null ? (<button className="spl_btn" onClick={() => addFavorite(data.id)} > <label>Add Favorite</label> </button> ) : ("") }
                      {data.favoritesFlag === 1 && localStorage.getItem("BearerToken") !== null ? (<button className="spl_btn rmv_fav" onClick={() => addFavorite(data.id)} > <label>Remove Favorite</label> </button> ) : ("") }
                      { localStorage.getItem("BearerToken") !== null ? (<button className="spl_btn"  onClick={selectBackground} value={data.id}> <label>Select Background</label> </button> ) : ("") }
                    </div>                  
                  </div>
                ))}
              </>              
            }
          </div>
         
          <div className="row magin-two-side mt-35">
            <h3 className="subcategorypage-type-title">Rotating Backgrounds</h3> 
            { categoryImageVideo.gifData?. map((data) => (
                <div className="col-sm-12">
                  <div className="pfl_pc_bx mb-2">
                    <img className="c-pointer" src={data.file_url} alt="thumbnail" onClick={() => openLightbox(data.file_url)} />                    
                  </div>
                  <div className="selectFavBtn">
                    {data.favoritesFlag === 0 && localStorage.getItem("BearerToken") !== null ? (<button className="spl_btn" onClick={() => addFavorite(data.id)} > <label>Add Favorite</label> </button> ) : ("") }
                    {data.favoritesFlag === 1 && localStorage.getItem("BearerToken") !== null ? (<button className="spl_btn rmv_fav" onClick={() => addFavorite(data.id)} > <label>Remove Favorite</label> </button> ) : ("") }
                    { localStorage.getItem("BearerToken") !== null ? (<button className="spl_btn" onClick={selectBackground} value={data.id}> <label>Select Background</label> </button> ) : ("") }
                  </div>
                </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
  return (
    <>
        {isLoading ? <LoadingSpinner /> : renderData}
    </>
  );
};

export default CategoryImgVideo;
