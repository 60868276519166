import React, { useState, useEffect } from 'react';
import FormLogo from '../elements/FormLogo';
import { Link, useLocation , useSearchParams } from "react-router-dom";
import puppetLogo from "../../css/images/android-chrome-192x192.png";
import LoadingSpinner from "../elements/LoadingSpinner";

const PaymentSuccess = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  let transaction_Id = location?.state?.txnId || undefined;
  const [searchParams] = useSearchParams();
  if (transaction_Id === undefined) {
    transaction_Id = searchParams.get('txnId');
  }
  const order_id = localStorage.getItem("order_id");
  useEffect(() => {
    localStorage.removeItem("order_id",);
    localStorage.removeItem("invoice_number",);
    localStorage.removeItem('billing_info');
    localStorage.removeItem('order_confirmation');
    localStorage.removeItem('terms_condition');
    localStorage.removeItem('selectedOrder');
    localStorage.removeItem('addMorePuppets');
    setTimeout(() => {
      setIsLoading(false);
    }, (2000));
  },[]);

  const renderData = (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
        <div className="container">
          <div className="odr_frm">
            <FormLogo title="" />  
              <div className="of_bx_alfa">
                <div className="row">
                  <div className="col-sm-12" style={{ textAlign: "center" }}>
                    <p><img src={puppetLogo} alt="puppet Logo" /></p>
                    <h2>Congratulations!</h2>
                    <p>Your order has been successfully placed!</p>
                    {order_id ? (
                      <p>Your Order number is {order_id}</p>
                    ) : (
                      <> </>
                    )}
                    {transaction_Id ? (
                      <p>Your Transaction reference is <strong>{transaction_Id}</strong></p>
                    ) : (
                      <> </>
                    )}
                    <p>Check <Link to="/my-order">My Orders</Link> page you the details.</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
  return (
    <>
        {isLoading ? <LoadingSpinner /> : renderData}
    </>
  );
};

export default PaymentSuccess;
