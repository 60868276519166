import { Routes, Route } from 'react-router-dom';
import Layout from './Layout';
//import MetaData from './elements/MetaData';

import Home from './routes/Home';
import Login from './routes/Login';
import Register from './routes/Register';
import ContactUs from './routes/ContactUs';
import OrderNow from './routes/OrderNow';
import PuppetgramCast from './routes/PuppetgramCast';
import Background from './routes/Background';
import PuppetProfile from './routes/PuppetProfile';
import CategoryImgVideo from './routes/CategoryImgVideo';
import CategoryVideos from './routes/CategoryVideos';
import AboutUs from './routes/AboutUs';
import GettingStarted from './routes/GettingStarted';
import TermsConditions from './routes/TermsConditions';
import Pricing from './routes/Pricing';
import Logout from './routes/Logout';
import OrderSummery from './routes/OrderSummery';
import MyFavorite from './routes/MyFavorite';
import MyAccount from './routes/MyAccount';
import MyOrder from './routes/MyOrder';
import Message from './routes/Message';
import OrderDetail from './routes/OrderDetail';
import MyFavoriteBackground from './routes/MyFavoriteBackground';
import CheckoutForm from "./routes/Checkout"
import PaymentSuccess from './routes/PaymentSuccess';
import PaymentFail from './routes/PaymentFail';

const Skeleton = () => {
  return (
    <>
      {/* <MetaData /> */}
      <Routes>
        <Route path="/" element={ <Layout /> } >
          <Route index element={ <Home /> } />
          <Route exact path="puppet-profiles" element={<PuppetgramCast />} />
          <Route exact path="puppet-profile/:slug" element={<PuppetProfile />} />
          <Route exact path="video-category" element={ <CategoryVideos /> } />
          <Route exact path="category" element={ <Background /> } />
          <Route exact path="category/:slug" element={ <CategoryImgVideo />} />
          <Route exact path="my-favorite" element={ <MyFavorite/> } />
          <Route exact path="my-favorite-background" element={ <MyFavoriteBackground/> } />
          <Route exact path="my-account" element={ <MyAccount/> } />
          <Route exact path="my-order" element={ <MyOrder/> } />
          <Route exact path="order-detail/:id" element={ <OrderDetail/> } />
          <Route exact path="checkout/" element={<CheckoutForm /> } />
          <Route exact path="message" element={ <Message/> } />
          <Route exact path="about-us" element={<AboutUs />} />
          <Route exact path="order-summery" element={<OrderSummery/>} />
          <Route exact path="payment-success" element={<PaymentSuccess/>} />
          <Route exact path="payment-fail" element={<PaymentFail/>} />
          <Route exact path="terms-conditions" element={<TermsConditions />} />
          <Route exact path="pricing" element={<Pricing />} />
          <Route exact path="order-now" element={ <OrderNow /> } />
          <Route exact path="contact-us" element={ <ContactUs /> } />
          <Route exact path="getting-started" element={ <GettingStarted /> } />
          <Route exact path="register"  element={ <Register /> } />
          <Route exact path="login" element={ <Login /> } />
          <Route exact path="logout" element={<Logout />}/>
        </Route>
      </Routes>
    </>
  );
};

export default Skeleton;