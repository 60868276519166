import React from "react";
import PgSpinner from "../elements/PgLoader";

export default function LoadingSpinner() {
    return (
        // id="cover-spin"
        <section className="full_width">
            <div className="container-fluid">
                <div className="row justify-content-md-center mb-3">
                    <div className="col-md-auto" >
                        <PgSpinner />
                    </div>
                </div>
            </div>
        </section>
    );
}