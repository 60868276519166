import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ENDPOINT from "../../constants/api-endpoints";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../elements/LoadingSpinner";


const CategoryVideos = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [categoryImageVideo, setCategoryImagesVideo] = useState([]);
  const navigate = useNavigate()
  const [removeFaviorate, setRemoveFaviorate] = useState(0);
  const [loginUserID, setLoginUserID] = useState(localStorage.getItem("loginUserId") || 0);

  useEffect(() => {
    axios.get(ENDPOINT.CATEGORY_VIDEOS + "/" + loginUserID, {})
      .then((response) => {
        setCategoryImagesVideo(response.data.data);
        setRemoveFaviorate(response.data.data.favoritesFlag);
        setTimeout(() => {
          setIsLoading(false);
        }, (2000));
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });      
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, (2000));
  }, []);


  const selectBackground = (event) => {
    // alert('what we can do');
    const id = event.currentTarget.getAttribute('value');
    navigate('/order-now',{state:{backgroundImageID:id, }});
  }

  const addFavorite = (id) => {
    const postData = {
      'id' : id,
      'type' : 'background_category',
    };
    const response = axios.post(ENDPOINT.ADD_PUPPET_FAVORITE, postData, {})
    .then((response) => {
      toast(response.data.message);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    })
    .catch((error) => {
      if(error.response.status === 401){
        toast("Login first");
      }
    });
  };
  const [show, setShow] = useState(false);
  const [videoOption, setVideo] = useState("");
  const [videoTitle, setVideoTitle] = useState("");

  const handleClose = () => {
    setShow(false);
  }
  const videoShow = (opt,title) => {
    setVideo(opt);
    setVideoTitle(title);
    setShow(true);
  }
  const renderData = (
    <>
      <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>{videoTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video width="100%" controls autoplay="true" >
            <source src={videoOption} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>     
      <section className="full_width common_heading" id="scrollContentTarget">
        <ToastContainer />
        <div className="container-fluid cont-pad">         
          <div className="row magin-two-side video_category">
            <h1 className="color-white text-center">Click on the Images Below to View Our</h1>
            <h2 className="color-white text-center">Custom-Edited Video Background Options</h2>
            <h3 className="color-white text-center">Available to personalize Your Custom Puppet Video</h3>  
            {categoryImageVideo.videoData?. map((data) => (
                <div className="col-sm-4">
                  <div className="pfl_pc_bx no-box-shadow background-bottom vgb_inner_pfl_pc_bx">                    
                    <img src={data.video_thumb_url} alt={data.video_title} title={data.video_title} onClick={() => videoShow(data.file_url,data.video_title)} />
                    <p className="title_right category_fs">{data.video_title}</p>
                    <div className="selectFavBtn">
                      {data.favoritesFlag === 0 && localStorage.getItem("BearerToken") !== null ? (<button className="spl_btn" onClick={() => addFavorite(data.id)} > <label>Add Favorite</label> </button> ) : ("") }
                      {data.favoritesFlag === 1 && localStorage.getItem("BearerToken") !== null ? (<button className="spl_btn rmv_fav" onClick={() => addFavorite(data.id)} > <label>Remove Favorite</label> </button> ) : ("") }
                      { localStorage.getItem("BearerToken") !== null ? (<button className="spl_btn" onClick={selectBackground} value={data.id}> <label>Select Background</label> </button> ) : ("") }
                    </div>
                  </div>                  
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
  return (
    <>
        {isLoading ? <LoadingSpinner /> : renderData}
    </>
  );
};

export default CategoryVideos;
