//LIVE
// PayPal
//~~~~~~~~~~
export const P_CLIENT_ID = process.env.P_CLIENT_ID || "Af6BkQr055GLQHAYoI22X-Y_sqkGEwjTSIVC6DTsjgJDsEl-mSQ_ydSfKbnEvP_u2nMn9Pgru_cYrVwN"
export const P_APP_SECRET = process.env.P_APP_SECRET || "ECpVq1Uar8ZnMiyJ_uGjz1qOemjd0KcnO3kaTWad7wDP-PN0OgtiDhL068xdBGMVORRiLLAzkK2Hr_VB"

// Stripe
//~~~~~~~~~~
export const S_PUBLIC_KEY = process.env.S_PUBLIC_KEY || "pk_live_51OhgVzLgXYNvq3r9tUihfbENgdvKl4X4VbNMKTwZiEQ5xeDghTXXmXi5S8bpoROgVamgMd7XttYGlbAwXmMTBOO700t1ezTVgy"

//TEST
// export const P_CLIENT_ID = process.env.P_CLIENT_ID || "AaFkolxj_pVh2horPAEslM_rdYz4BQku1DRZjgOQ8mt7dQSPj-ubrWNU12HtRgkbXHy29HdqjonoA047"
// export const P_APP_SECRET = process.env.P_APP_SECRET || "EEdaZumOJp3fcSKCClUuxcygnft41cDPf-ZuS30yrVCN4JVyF_l1WOp65aid4MR9pdBI6i9WHa8-UaVY"
// export const S_PUBLIC_KEY = process.env.S_PUBLIC_KEY || "pk_test_51OhgVzLgXYNvq3r9tjtKCmkBiJoHqVMZUCIMOZt5n0WeQ33EB37M1IXbPWaDxgyDT3nfpShj5oNtpd9EpntcT1Km005H7lcMQj"
